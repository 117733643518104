<template>
  <div>
    <div class="card" v-loading="isLoading">
      <div class="card-header">
        <h5 class="card-title">Link Map</h5>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <div id="myDiagramDiv" style="flex-grow: 1; height: 700px; border: 1px solid black; position: relative; -webkit-tap-highlight-color: rgba(255, 255, 255, 0);"><canvas tabindex="0" width="952" height="478" style="position: absolute; top: 0px; left: 0px; z-index: 2; user-select: none; touch-action: none; width: 952px; height: 478px;">This text is displayed if your browser does not support the Canvas HTML element.</canvas><div style="position: absolute; overflow: auto; width: 952px; height: 478px; z-index: 1;"><div style="position: absolute; width: 1px; height: 1px;"></div></div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import Swal from 'sweetalert2';
  import { mapGetters, mapActions } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { ErrorFromatterMixin } from 'src/mixins/errorFormatter.mixin'
  import go from 'gojs'
  export default{
    mixins: [NotifyMixin, ErrorFromatterMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data () {
      return {
        isLoading: true,
        diagram: null,
        black: 'black',
        linkColorInternalHighToLow: 'green',
        linkColorInternalLowToHigh: 'red',
        linkColorInternalAdjascent: 'yellow',
        linkColors: {
          navigational: 'black',
          external: {
            highToLow: 'green',
            lowToHigh: 'red',
            adjascent: 'yellow'
          }
        }
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        console.log('this.isLoading', this.isLoading)
        this.loadLinkMap()
        this.isLoading = false
      },
      loadLinkMap () {
        const mainBodyWidth = 450;
        const mainBodyHeight = 150;
        const cornerLinkWidth = 140;
        const cornerLinkHeight = 40;
        const cornerLinkXoffset = cornerLinkHeight / 2;
        const cornerLinkYoffset = cornerLinkWidth / 2;
        const dataColLabelWidth = 75;
        const dataColValWidth = 350;

        // colors used, named for easier identification
        const blue = "#0288D1";
        const green = "green";
        const yellow = "yellow";
        const red = "red";

        // Since 2.2 you can also author concise templates with method chaining instead of GraphObject.make
        // For details, see https://gojs.net/latest/intro/buildingObjects.html
        const $ = go.GraphObject.make;  // for conciseness in defining templates

        var yellowgrad = $(go.Brush, "Linear", { 0: "rgb(254, 201, 0)", 1: "rgb(254, 162, 0)" });
        var greengrad = $(go.Brush, "Linear", { 0: "#98FB98", 1: "#9ACD32" });
        var bluegrad = $(go.Brush, "Linear", { 0: "#B0E0E6", 1: "#87CEEB" });
        var redgrad = $(go.Brush, "Linear", { 0: "#C45245", 1: "#871E1B" });
        var whitegrad = $(go.Brush, "Linear", { 0: "#F0F8FF", 1: "#E6E6FA" });

        var bigfont = "bold 13pt Helvetica, Arial, sans-serif";
        var smallfont = "bold 11pt Helvetica, Arial, sans-serif";

        // Common text styling
        function textStyle() {
          return {
            margin: 6,
            wrap: go.TextBlock.WrapFit,
            textAlign: "center",
            editable: true,
            font: bigfont
          }
        }

        function nodeMoved(e, obj) {
          // var shape = obj.findObject("SHAPE");
          // // Return the Shape's fill and stroke to the defaults
          // shape.fill = obj.data.color;
          // shape.stroke = null;
          // // Return the TextBlock's stroke to its default
          // var text = obj.findObject("TEXT");
          // text.stroke = "black";
          console.log("node moved");
        };

        this.diagram =
          $(go.Diagram, "myDiagramDiv",
            {
              // have mouse wheel events zoom in and out instead of scroll up and down
              "toolManager.mouseWheelBehavior": go.ToolManager.WheelZoom,
              initialAutoScale: go.Diagram.Uniform,
              // "linkingTool.direction": go.LinkingTool.ForwardsOnly,
              // layout: $(go.LayeredDigraphLayout, { isInitial: false, isOngoing: false, layerSpacing: 50 }),
              "undoManager.isEnabled": true
            });
        
        // diagram.addDiagramListener("SelectionMoved",
        //   function(e) {
        //     evt = e;
        //     var part = e.subject.part;
        //     console.log('sucka moved');
        //     console.log(e);
        //   });

        this.diagram.nodeTemplate =
          $(go.Node, "Spot",
            new go.Binding("location", "loc").makeTwoWay(),
            $(go.Shape, "Rectangle",
              {
                fill: "white",
                width: mainBodyWidth, height: mainBodyHeight
              }
            ),
            $(go.Panel, "Table",
              // { alignment: new go.Spot(0, 0) },
              $(go.RowColumnDefinition, { column: 0, separatorStroke: "black", background: "#d3d3d3" }),
              $(go.RowColumnDefinition, { column: 1, separatorStroke: "black", background: "#f4f0ec" }),
              $(go.RowColumnDefinition, { row: 0, separatorStroke: "black" }),
              $(go.RowColumnDefinition, { row: 1, separatorStroke: "black" }),
              $(go.RowColumnDefinition, { row: 2, separatorStroke: "black" }),
              $(go.RowColumnDefinition, { row: 3, separatorStroke: "black" }),
              $(go.RowColumnDefinition, { row: 4, separatorStroke: "black" }),
              $(go.TextBlock, "URL",
                {
                  row: 0,
                  wrap: go.TextBlock.None, margin: 5, width: dataColLabelWidth,
                  isMultiline: false, textAlign: 'left',
                  font: '10pt  Segoe UI,sans-serif'
                }
              ),
              $(go.TextBlock, "Page Rank",
                {
                  row: 1,
                  wrap: go.TextBlock.None, margin: 5, width: dataColLabelWidth,
                  isMultiline: false, textAlign: 'left',
                  font: '10pt  Segoe UI,sans-serif'
                },
              ),
              $(go.TextBlock, "Link Juice",
                {
                  row: 2,
                  wrap: go.TextBlock.None, margin: 5, width: dataColLabelWidth,
                  isMultiline: false, textAlign: 'left',
                  font: '10pt  Segoe UI,sans-serif'
                },
              ),
              $(go.TextBlock, "Page Name",
                {
                  row: 3,
                  wrap: go.TextBlock.None, margin: 5, width: dataColLabelWidth,
                  isMultiline: false, textAlign: 'left',
                  font: '10pt  Segoe UI,sans-serif'
                },
              ),
              $(go.TextBlock, "Page Type",
                {
                  row: 4,
                  wrap: go.TextBlock.None, margin: 5, width: dataColLabelWidth,
                  isMultiline: false, textAlign: 'left',
                  font: '10pt  Segoe UI,sans-serif'
                },
              ),
              $(go.TextBlock, "",
                {
                  column: 1, row: 0,
                  wrap: go.TextBlock.None, margin: 2, width: dataColValWidth,
                  isMultiline: false, editable: true, textAlign: 'left',
                  font: '10pt  Segoe UI,sans-serif', stroke: 'black'
                },
                new go.Binding("text", "url").makeTwoWay()
              ),
              $(go.TextBlock, "",
                {
                  column: 1, row: 1,
                  wrap: go.TextBlock.None, margin: 2, width: dataColValWidth,
                  isMultiline: false, editable: true, textAlign: 'left',
                  font: '10pt  Segoe UI,sans-serif', stroke: 'black'
                },
                new go.Binding("text", "page_rank").makeTwoWay()
              ),
              $(go.TextBlock, "",
                {
                  column: 1, row: 2,
                  wrap: go.TextBlock.None, margin: 2, width: dataColValWidth,
                  isMultiline: false, editable: true, textAlign: 'left',
                  font: '10pt  Segoe UI,sans-serif', stroke: 'black'
                },
                new go.Binding("text", "link_juice").makeTwoWay()
              ),
              $(go.TextBlock, "",
                {
                  column: 1, row: 3,
                  wrap: go.TextBlock.None, margin: 2, width: dataColValWidth,
                  isMultiline: false, editable: true, textAlign: 'left',
                  font: '10pt  Segoe UI,sans-serif', stroke: 'black'
                },
                new go.Binding("text", "page_name").makeTwoWay()
              ),
              $(go.TextBlock, "",
                {
                  column: 1, row: 4,
                  wrap: go.TextBlock.None, margin: 2, width: dataColValWidth,
                  isMultiline: false, editable: true, textAlign: 'left',
                  font: '10pt  Segoe UI,sans-serif', stroke: 'black'
                },
                new go.Binding("text", "page_type").makeTwoWay()
              )
            ),
            $(go.Panel, "Auto",
              { alignment: new go.Spot(0, 0, cornerLinkYoffset, -cornerLinkXoffset) },
              $(go.Shape, "Rectangle",
                {
                  width: cornerLinkWidth, height: cornerLinkHeight, fill: "white", stroke: "dodgerblue", strokeWidth: 1
                  // modify blow to perhaps highlight using findLinksOutOf
                  // mouseEnter: function(e, shape) { /* find links and highlight them */ },
                  // mouseLeave: function(e, shape) { /* find links and make them normal */ }
                },
                {
                  toolTip:
                    $("ToolTip",
                      { "Border.stroke": "black", "Border.strokeWidth": 2 },
                      $(go.TextBlock, { margin: 8, stroke: "black", font: "bold 16px sans-serif" },
                        new go.Binding("text", "geo")))
                }
              ),
              $(go.TextBlock, "C",
                {
                  alignment: new go.Spot(0.401, 0.05),
                  font: '8pt  Segoe UI,sans-serif',
                }
              ),
              $(go.Shape, "Circle",
                {
                  alignment: new go.Spot(0.4, 0),
                  width: 12, height: 12, fill: "rgba(0,0,0,0)", portId: "internalInlinkContextual", toLinkable: true, cursor: "pointer"
                }
              ),
              $(go.TextBlock, "N",
                {
                  alignment: new go.Spot(0.601, 0.05),
                  font: '8pt  Segoe UI,sans-serif',
                }
              ),
              $(go.Shape, "Circle",
                {
                  alignment: new go.Spot(0.6, 0),
                  width: 12, height: 12, fill: "rgba(0,0,0,0)", portId: "internalInlinkNavigational", toLinkable: true, cursor: "pointer"
                }
              ),
              $(go.TextBlock, "INTERNAL INLINK")
            ),
            $(go.Panel, "Auto",
              { alignment: new go.Spot(1, 0, -cornerLinkYoffset, -cornerLinkXoffset) },
              $(go.Shape, "Rectangle",
                { width: cornerLinkWidth, height: cornerLinkHeight, fill: "white", stroke: "dodgerblue", strokeWidth: 1,
                portId: "externalInlink", toLinkable: true, cursor: "pointer", toSpot: go.Spot.Top }),
              $(go.TextBlock, "EXTERNAL INLINK")
            ),
            $(go.Panel, "Auto",
              { alignment: new go.Spot(0, 1, cornerLinkYoffset, cornerLinkXoffset) },
              $(go.Shape, "Rectangle",
                { width: cornerLinkWidth, height: cornerLinkHeight, fill: "white", stroke: "dodgerblue", strokeWidth: 1 }
              ),
              $(go.TextBlock, "C",
                {
                  alignment: new go.Spot(0.401, 1.05),
                  font: '8pt  Segoe UI,sans-serif',
                }
              ),
              $(go.Shape, "Circle",
                {
                  alignment: new go.Spot(0.4, 1),
                  width: 12, height: 12, fill: "rgba(0,0,0,0)", portId: "internalOutlinkContextual", fromLinkable: true, cursor: "pointer"
                }
              ),
              $(go.TextBlock, "N",
                {
                  alignment: new go.Spot(0.601, 1.05),
                  font: '8pt  Segoe UI,sans-serif',
                }
              ),
              $(go.Shape, "Circle",
                {
                  alignment: new go.Spot(0.6, 1),
                  width: 12, height: 12, fill: "rgba(0,0,0,0)", portId: "internalOutlinkNavigational", fromLinkable: true, cursor: "pointer"
                }
              ),
              $(go.TextBlock, "INTERNAL OUTLINK")
            ),
            $(go.Panel, "Auto",
              { alignment: new go.Spot(1, 1, -cornerLinkYoffset, cornerLinkXoffset) },
              $(go.Shape, "Rectangle",
                { width: cornerLinkWidth, height: cornerLinkHeight, fill: "white", stroke: "dodgerblue", strokeWidth: 1,
                  portId: "externalOutlink", fromLinkable: true, cursor: "pointer", fromSpot: go.Spot.Bottom }),
              $(go.TextBlock, "EXTERNAL OUTLINK")
            )
          );

        // The color of a link (including its arrowhead) is red only when both
        // connected nodes have data that is ".critical"; otherwise it is blue.
        // This is computed by the binding converter function.
        this.diagram.linkTemplate =
          $(go.Link,
            { routing: go.Link.AvoidsNodes, corner: 3, fromSpot: go.Spot.Bottom, toSpot: go.Spot.Top, curve: go.Link.Bezier, toShortLength: 3 },
            $(go.Shape,
              { strokeWidth: 4 },
              //new go.Binding("stroke", "", linkColorConverter)),
              new go.Binding("stroke", "color")),
            $(go.Shape,  // arrowhead
              { toArrow: "Triangle", stroke: null, scale: 1.5 },
              // new go.Binding("fill", "", linkColorConverter))
              new go.Binding("fill", "color"))
          );

        // diagram.nodeTemplate.contextMenu =
        //   $(go.Adornment, "Vertical",
        //     { background: "gray", defaultAlignment: go.Spot.Top, padding: 10 },
        //     new go.Binding("itemArray",
        //       (e) => {  // OBJ is this Button
        //         ed = e;
        //         console.log('objs', e);
        //         var node = diagram.findNodeForKey(e.key);
        //         var ioPort = node.findPort('internalOutlink');
        //         var links = ioPort.findLinksOutOf();
        //         console.log('links', links);
        //         for(var i = 0; i < links.length; i++) {
        //           console.log('link', l);
        //         }
        //         return links;
        //         //return e.findLinksOutOf();
        //         // var contextmenu = obj.part;  // the Button is in the context menu Adornment
        //         // var part = contextmenu.adornedPart;  // the adornedPart is the Part that the context menu adorns
        //         // // now can do something with PART, or with its data, or with the Adornment (the context menu)
        //         // if (part instanceof go.Link) alert(linkInfo(part.data));
        //         // else if (part instanceof go.Group) alert(groupInfo(contextmenu));
        //         // else alert(nodeInfo(part.data));
        //       })
        //   );

        // diagram.linkTemplate =
        //   $(go.Link,
        //     { routing: go.Link.AvoidsNodes, corner: 3, fromSpot: go.Spot.Bottom, toSpot: go.Spot.Top },
        //     $(go.Shape),
        //     $(go.Shape, { toArrow: "Standard" })
        //   );

        // diagram.layout = $(go.LayeredDigraphLayout, { columnSpacing: 10 });

        // diagram.toolManager.linkingTool.temporaryLink.routing = go.Link.Orthogonal;

        var model =
          new go.GraphLinksModel(
            { linkFromPortIdProperty: "fromPort",  // required information:
              linkToPortIdProperty: "toPort",      // identifies data property names
              nodeDataArray: [
                {
                  key: "Alpha",
                  url: "https://www.innovetpet.com/products/cbd-oil-for-dogs?variant=40873766420",
                  page_rank: "",
                  link_juice: "",
                  page_name: "",
                  page_type: "",
                  loc: ""
                },
                {
                  key: "Beta",
                  url: "",
                  page_rank: "",
                  link_juice: "",
                  page_name: "",
                  page_type: "",
                  loc: ""
                },
                {
                  key: "Gamma",
                  url: "",
                  page_rank: "",
                  link_juice: "",
                  page_name: "",
                  page_type: "",
                  loc: ""
                },
                {
                  key: "Delta",
                  url: "",
                  page_rank: "",
                  link_juice: "",
                  page_name: "",
                  page_type: "",
                  loc: ""
                },
                {
                  key: "Zeta",
                  url: "",
                  page_rank: "",
                  link_juice: "",
                  page_name: "",
                  page_type: "",
                  loc: ""
                },
                {
                  key: "Eta",
                  url: "",
                  page_rank: "",
                  link_juice: "",
                  page_name: "",
                  page_type: "",
                  loc: ""
                },
                {
                  key: "Theta",
                  url: "",
                  page_rank: "",
                  link_juice: "",
                  page_name: "",
                  page_type: "",
                  loc: ""
                }
              ],
              linkDataArray: [
                // no predeclared links
              ] });

        this.diagram.model = model;

        

        model.addChangedListener(e => {
          if (e.isTransactionFinished) {
            var tx = e.object;
            if (tx instanceof go.Transaction && window.console) {
              window.console.log('====================');
              window.console.log(tx.toString());
              window.console.log('====================');
              tx.changes.each(c => {
                if (c.model) {
                  window.console.log("  " + c.toString());
                  window.console.log(c.model);
                  this.updateLinks(c.model.linkDataArray);
                }
              });
            }
          }
        });
      },
      updateLinks(links) {
        window.console.log('========link data array');
        window.console.log(links);
        if (links instanceof Array) {
          for (var i = 0; i < links.length; i++) {
            var c = this.linkColorConverter(links[i], null);
            this.diagram.model.setDataProperty(links[i], "color", c);
          }
          // links.each(l => {
          //   linkColorConverter(l, null);
          // });
        }
      },
      linkColorConverter(linkdata, elt) {
        var heightBuffer = 100;
        // console.log('linkdata', linkdata);
        // console.log('elt', elt);
        // console.log('fromNode', elt.fromNode);
        // ld = linkdata;
        // ed = elt;
        console.log('from name:', linkdata.from);
        console.log('to name:', linkdata.to);
        var fromNode = this.diagram.findNodeForKey(linkdata.from);
        var toNode = this.diagram.findNodeForKey(linkdata.to);
        console.log('from node', fromNode)
        console.log('to node', toNode)
        // fn = fromNode;
        // tn = toNode;
        var toNodeLocY = toNode.location.y
        var fromNodeLocY = fromNode.location.y
        console.log('fromNode y, toNode y, buffer', fromNodeLocY, toNodeLocY, heightBuffer)
        if (linkdata.fromPort === "internalOutlinkNavigational") {
          console.log('internalOutlinkNavigational', this.black)
          return this.black;
        } else if (toNode.location.y > (fromNode.location.y + heightBuffer)) {
          console.log('from node is higher', this.linkColorInternalHighToLow)
          return this.linkColorInternalHighToLow;
        } else if (fromNode.location.y > (toNode.location.y + heightBuffer)) {
          console.log('from node  is lower', this.linkColorInternalLowToHigh)
          return this.linkColorInternalLowToHigh;
        } else {
          console.log('from and to nodes fairly even', this.linkColorInternalAdjascent)
          return this.linkColorInternalAdjascent;
        }
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style lang="scss">
  // nothing
</style>
